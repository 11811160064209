<template>
  <div class="basic_pp bottom_up" v-if="pp" :class="{ off: off }">
    <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
    <div class="content bg-body">
      <div class="header position-sticky flex-between-center pb-3">
        <h5 class="text-truncate">{{ terms[terms_idx].title }}</h5>
        <i class="fal fa-times p-2" @click="pp_off()"></i>
      </div>
      <div
        class="body overflow-auto fs-px-13"
        ref="basic_pp_body"
        v-html="terms[terms_idx].desc"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      terms: [
        {
          title: '이용자 약관 정보 동의',
          desc: `<p>제1항 총칙
			<br/>
			<br/>
제1조 (목적)
			<br/>
이 서비스 약관은 주식회사 골프앤요트(이하 "회사"라 합니다)가 제공하는 상품권 서비스를 이용하는데 필요한 이용자와 회사간의 권리 및 의무 기타 제반사항을 규정함을 목적으로 합니다.
			<br/>
			<br/>
제2조 (정의)
			<br/>
①	개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
			<br/>
②	‘서비스’란 이용 고객 또는 회원이 PC, 이동전화, 스마트폰 등 각종 유무선 기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 제공하는 모든 서비스를 말합니다.
			<br/>
③	'회원'이란 회사 ‘골프앤요트’에 이용계약을 체결한 개인이나 법인 또는 법인에 준하는 단체를 말하며, ‘골프앤요트’의 정보를 지속적으로 제공 받으며, ‘골프앤요트’이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
			<br/>
④	이 약관에서 사용하는 용어의 정의는 이 조항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
			<br/>
			<br/>
제3조 (약관의 효력 및 변경)
			<br/>
①	약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.
			<br/>
②	이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에 가입함으로써 효력이 발생합니다.
			<br/>
③	회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 ‘골프앤요트’의 초기 서비스 화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.
			<br/>
④	회사는 필요하다고 인정되는 경우 이 약관을 개정할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 제2항과 같은 방법으로 그 적용일자 7일 전부터 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
			<br/>
⑤	회사가 제4항에 따라 변경 약관을 공지 또는 통지하면서, 회원에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회원이 변경 약관에 동의한 것으로 간주합니다. 회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
			<br/>
⑥	이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래등에서의 소비자보호지침 및 전기통신기본법, 전기통신사업법 등 기타 관계법령 또는 상관례에 따릅니다.
			<br/>
			<br/>
			<br/>
제2장 서비스 이용 계약
			<br/>
			<br/>
제4조 (이용계약의 성립)
			<br/>
①	회원이 이용신청 절차에 따라 이용약관을 확인하고 "동의"버튼을 누르시면 약관에 동의하는 것으로 간주됩니다.
			<br/>
②	이용계약은 고객의 이용신청에 대하여 회사가 승낙함으로써 성립합니다.
			<br/>
			<br/>
제5조 (이용신청 및 승낙)
			<br/>
①	이용신청은 ´골프앤요트´ 신규회원가입 화면에서 고객이 다음 사항을 기재 및 동의하여 신청합니다.
			<br/>
1.	이용약관 동의
			<br/>
2.	고객 본인의 개인정보 기입
			<br/>
3.	개인정보 관련 수집, 이용 및 제공에 관한 동의
			<br/>
4.	기타 회사가 필요하다고 인정하는 사항
			<br/>
②	회사는 본 조 제1항에서 정한 사항을 정확히 기재 및 동의하여 이용 신청한 고객에 대하여 ´닷컴´ 이용신청을 승낙합니다.
			<br/>
③	이용신청 승낙의 성립은 회사의 승낙이 회원에게 도달한 시점으로 합니다.
			<br/>
④	회사는 다음과 같은 경우 이용신청자의 이용 신청 승낙을 제한할 수 있고, 이 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
			<br/>
1.	기술상 서비스 제공이 불가능한 경우
			<br/>
2.	다른 사람의 명의사용 등 이용자 등록 시 허위로 신청하는 경우
			<br/>
3.	이용자 등록 사항을 누락하거나 오기하여 신청하는 경우
			<br/>
4.	사회의 안녕 질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우
			<br/>
5.	악성 프로그램 및 버그를 이용하거나 시스템 취약점을 악용하는 등 부정한 방법을 서비스에 사용한 경우
			<br/>
6.	기타 회사가 정한 이용신청 요건이 만족되지 않았을 경우
			<br/>
⑤	회원의 자격에 따라 ´닷컴´ 이용의 일부가 제한될 수 있으며, 회사 서비스 변동(명의변경, 해지 등)에 따라 회원자격 및 ´닷컴´ 이용범위가 변동될 수 있습니다.
			<br/>
제6조(회원정보의 변경)
			<br/>
①	회원은 이용신청 시 기재한 사항이 변경되었을 경우, 회사에서 정한 방법으로 이를 수정하거나 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.
			<br/>
②	회사는 회원이 변경사항을 알리지 아니하여 발생한 손해에 대하여 책임지지 않습니다.
			<br/>
③	회원은 회원정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위하여 본인인증결과값(본인확인기관이 제공한 정보), ID 등은 수정이 불가능합니다.
			<br/>
			<br/>
			<br/>
제 3 장 골프앤요트 이용
			<br/>
			<br/>
제7조 (서비스의 이용개시)
			<br/>
①	회사는 회원의 이용 신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.
			<br/>
②	회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 ‘골프앤요트’에 공시하거나 회원에게 이를 통지합니다.
			<br/>
			<br/>
제8조 (서비스의 이용기간)
			<br/>
①	´골프앤요트´의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무 상이나 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영 상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다.
			<br/>
②	회사는 서비스를 일정범위로 분할하여 각 범위별로 이용 가능한 시간을 별도로 정할 수 있습니다.
			<br/>
			<br/>
제9조 (서비스의 변경 및 중지)
			<br/>
①	회사는 수시로 서비스의 향상을 위하여 기존 ´닷컴´의 전부 또는 일부 내용을 별도의 통지 없이 변경할 수 있습니다.
			<br/>
②	회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
			<br/>
1.	서비스용 설비의 보수 등 공사로 인한 부득이한 경우
			<br/>
2.	회원이 회사의 영업활동을 방해하는 경우
			<br/>
3.	정전, 통신두절, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
			<br/>
4.	서비스 제공업자와의 계약 종료 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우
			<br/>
5.	기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
			<br/>
제10조 (게시물 또는 내용물의 삭제)
			<br/>
회사는 회원이 게시하거나 전달하는 서비스 내의 모든 내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.
			<br/>
1.	회사, 다른 회원 또는 제 3 자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
			<br/>
2.	공공질서 및 미풍양속에 위반되는 경우
			<br/>
3.	범죄적 행위에 결부된다고 인정되는 내용인 경우
			<br/>
4.	회사 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
			<br/>
5.	제 2 항 소정의 세부이용지침을 통하여 회사에서 규정한 게시기간을 초과한 경우
			<br/>
6.	회사에서 제공하는 서비스와 관련 없는 내용인 경우
			<br/>
7.	불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
			<br/>
8.	기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우
			<br/>
			<br/>
제11조 (게시물의 저작권)
			<br/>
①	회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 소유하며 회사는 서비스 내에 이를 게시할 수 있는 권리를 갖습니다.
			<br/>
②	회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 기타 권리를 침해하더라도 이에 대한 민ㆍ형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권 기타 권리를 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우, 회원은 회사의 면책을 위하여 노력하여야 하며, 회원은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다.
			<br/>
③	회사는 회원이 이용계약을 해지하고 ‘닷컴’을 탈퇴하거나 적법한 사유로 해지된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.
			<br/>
④	회사가 작성한 저작물에 대한 저작권은 회사에 귀속됩니다.
			<br/>
⑤	회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제 3 자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.
			<br/>
			<br/>
제12조 (서비스의 재판매 금지)
			<br/>
회원은 상업적인 목적을 위하여 본 서비스 자체, 서비스 이용 또는 서비스 내용의 일부 또는 전부를 복사, 복제, 판매, 재판매 또는 이용할 수 없습니다.
			<br/>
			<br/>
			<br/>
제 4 장 계약당사자의 의무
			<br/>
			<br/>
제13조 (회사의 의무)
			<br/>
①	회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는데 최선을 다하여야 합니다.
			<br/>
②	회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.
			<br/>
③	회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보를 특정 회원의 정보를 구별할 수 없는, 통계자료로 작성하여 이를 사용할 수 있고, 이를 위하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의해 서비스 이용이 변경되는 것은 회원의 책임입니다.
			<br/>
④	회사는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
			<br/>
			<br/>
제14조 (회원의 의무)
			<br/>
①	회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 안됩니다.
			<br/>
1.	이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 ID 및 비밀번호를 도용, 부정하게 사용하는 행위
			<br/>
2.	명의자 허락 없이 본인 인증 절차를 수행하는 행위
			<br/>
3.	회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위
			<br/>
4.	타인의 명예를 손상시키거나 불이익을 주는 행위
			<br/>
5.	게시판 등에 음란물을 게재하거나 음란 사이트를 연결(링크)하는 행위
			<br/>
6.	회사 또는 제 3 자의 저작권 등 기타 권리를 침해하는 행위
			<br/>
7.	공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위
			<br/>
8.	서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
			<br/>
9.	서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)을 전송하는 행위
			<br/>
10.	타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
			<br/>
11.	다른 회원의 개인정보를 수집, 저장, 공개하는 행위
			<br/>
12.	자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위
			<br/>
13.	관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위
			<br/>
14.	회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위
			<br/>
15.	컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 e-mail으로 발송하는 행위
			<br/>
16.	기타 불법적이거나 부당한 행위
			<br/>
②	회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
			<br/>
③	회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며 특히 해킹, 광고를 통한 수익, 음란 ’골프앤요트’를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.
			<br/>
④	회원은 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 완전한 정보(이하 "등록정보")를 제공하여야 합니다.
			<br/>
⑤	회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 합니다. 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우, 기타 회원이 본 조 제1항에 명시된 행위를 한 경우에 회사는 회원의 서비스 이용을 제한 또는 중지 할 수 있습니다.
			<br/>
			<br/>
제15조 (회원의 아이디(ID) 및 비밀번호에 대한 의무)
			<br/>
①	회원의 아이디(ID)와 비밀번호에 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.
			<br/>
②	회원은 자신의 아이디(ID) 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
			<br/>
③	회원이 자신의 아이디(ID) 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에 통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.
			<br/>
			<br/>
제16조 (회원에 대한 통지)
			<br/>
①	회원에 대한 통지를 하는 경우 회사는 공지사항 게시, 회원이 등록한 e-mail 주소 또는 SMS 등으로 할 수 있습니다.
			<br/>
②	회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.
			<br/>
			<br/>
제17조 (개인정보보호)
			<br/>
회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관한 사항은 관련법령 및 회사가 정하는 “개인정보취급방침”에 정한 바에 따릅니다.
			<br/>
			<br/>
제18조 (양도금지)
			<br/>
회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며 게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.
			<br/>
			<br/>
			<br/>
제5장 계약해지 및 이용제한
			<br/>
			<br/>
제19조 (계약해지 및 이용제한)
			<br/>
①	회원이 ‘골프앤요트’ 이용계약을 해지하고자 할 경우에는 본인이 ‘골프앤요트’ 상에서 또는 회사가 정한 별도의 이용방법으로 회사에 해지신청을 하여야 합니다.
			<br/>
②	회사는 회원이 회원의 의무를 이행하지 않을 경우 사전 통지 없이 이용계약을 해지하거나 또는 서비스 이용을 중지할 수 있습니다.
			<br/>
③	회사는 회원이 이용계약을 체결하여 ID와 비밀번호를 부여 받은 후에라도 회원의 자격에 따른 ’골프앤요트’ 이용을 제한할 수 있습니다.
			<br/>
④	회사는 회원 가입 후 어느 정도의 기간 동안 사용 이력이 없는 회원에 대해 사용의사를 묻는 고지를 하고, 회사가 정한 기한 내에 답변이 없는 경우 이용계약을 해지할 수 있습니다.
			<br/>
⑤	본 조 제 2 항 및 제 3 항의 회사 조치에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있습니다.
			<br/>
⑥	본 조 제 5 항의 이의가 정당하다고 회사가 인정하는 경우, 회사는 즉시 ‘골프앤요트’의 이용을 재개합니다.
			<br/>
			<br/>
			<br/>
제6장 손해배상 등
			<br/>
			<br/>
제 20조 (손해배상)
			<br/>
①	회원이 이 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.
			<br/>
②	회원이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
			<br/>
			<br/>
제21조 (면책사항)
			<br/>
①	회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
			<br/>
②	회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.
			<br/>
③	회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 ‘닷컴’에 게재한 내용의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다.
			<br/>
④	회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
			<br/>
			<br/>
제22조 (분쟁해결)
			<br/>
①	회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상 처리하기 위하여 피해 보상 처리 기구인 고객만족센터를 설치•운영합니다.
			<br/>
②	회사는 회원으로부터 제출되는 불만 사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 즉시 통보해 드립니다.
			<br/>
③	회사는 회원간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해 구제 신청이 있는 경우에는 공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
			<br/>
			<br/>
제23조 (준거법 및 관할법원)
			<br/>
①	회원의 ‘골프앤요트’ 사용으로 발생되는 모든 법적 문제는 대한민국 관계법령의 적용을 받습니다.
			<br/>
②	서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.
			<br/>
③	본 조 제 2 항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
			<br/>
			<br/>
			<br/>
본 약관은 2023년 09월 01일부터 시행합니다.
			<br/>
			<br/>
		</p>`,
        },
        {
          title: '개인정보 처리방침 동의',
          desc: `<p><span style="">주식회사 골프앤요트(www.golf-yacht.com)(이하 회사라 한다)는 「개인정보 보호법」 제30조에 따라 정부주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립&middot;공개합니다.</span></p>
<br>

<br>
<p><strong><span style="">제1조 (개인정보의 처리 목적)</span></strong></p>
<p><span style="">회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</span></p>

<li>1. 홈페이지 회원가입 및 관리</span></li>
<p><span style="">회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별&middot;인증, 회원자격 유지&middot;관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의 여부 확인, 각종 고지&middot;통지, 고충처리 목적으로 개인정보를 처리합니다.</span></p>


<li>2. 민원사무 처리</span></li>

<p><span style="">민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락&middot;통지, 처리결과 통보 목적으로 개인정보를 처리합니다.</span></p>

<li>3. 재화 또는 서비스 제공</li>

<p><span style="">서비스 제공, 콘텐츠 제공, 본인인증, 연령인증 등을 목적으로 개인정보를 처리합니다.</span></p>

<li>4. 마케팅 및 광고에의 활용</li>

<p><span style="">신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</span></p>
<br>
<p><strong><span style="">제2조(개인정보의 처리 및 보유 기간)</span></strong></p>
<li>1. <span style="">앱 회원가입 및 관리</span></li>

<p><span style="">앱 회원가입 및 관리와 관련한 개인정보는 수집&middot;이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유&middot;이용됩니다.</span></p>
<p><span style="">보유근거 : 관련 법령에 근거</span></p>
<p><span style="">관련법령: 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년</span></p>
<p><span style="">소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</span></p>
<p><span style="">대금결제 및 재화 등의 공급에 관한 기록 : 5년</span></p>
<p><span style="">계약 또는 청약철회 등에 관한 기록 : 5년</span></p>
<p><span style="">표시/광고에 관한 기록 : 6개월</span></p>
<p><span style="">예외사유 : 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만, 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.</span></p>

<li>2. <span style="">민원사무 처리</span></li>

<p><span style="">민원사무 처리와 관련한 개인정보는 수집&middot;이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유&middot;이용됩니다.</span></p>
<p><span style="">보유근거 : 관련 법령에 근거 (전항과 동일)</span></p>

<li>3. <span style="">마케팅 및 광고에의 활용</span></li>

<p><span style="">마케팅 및 광고에의 활용과 관련한 개인정보는 수집&middot;이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유&middot;이용됩니다.</span></p>
<p><span style="">보유근거 : 관련 법령에 근거 (전항과 동일)</span></p>
<br>
<p><strong><span style="">제3조 (개인정보의 제3자 제공)</span></strong></p>
<li>1. 회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하여, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」, 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</span></li>
<li>2. 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</span></li>


<p><span style="">제공받는 자의 개인정보 이용목적 : 이메일, 휴대폰번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키 제공받는 자의 보유&middot;이용기간 : 1년</span></p>
<br>

<p><strong><span style="">제4조 (정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법)</span></strong></p>

<li>1. 정보주체는 회사에 대해 언제든지 개인정보 열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할 수 있습니다.</span></li>
<li>2. 제1항에 따른권리 행사는 주식회사 골프앤요트에 대해 「개인정보 보호법」시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 골프앤요트는 이에 대해 지체 없이 조치하겠습니다.</span></li>
<li>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</span></li>
<li>4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」, 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</span></li>
<li>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</span></li>
<li>6. 회사는 정보주체 관리에 따른 열람의 요구, 정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</span></li>
<br>
<p><strong><span style="">제5조 (처리하는 개인정보의 항목 작성)</span></strong></p>

<li>1. 회사는 다음의 개인정보 항목을 처리하고 있습니다.</span>

<li>A. 홈페이지 회원가입 및 관리<br />필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키</span></li>
</li>
<br>
<p><strong><span style="">제6조 (개인정보의 파기)</span></strong></p>

<li>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</span></li>
<li>정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속보전하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</span></li>
<li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</span>

<li>파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</span></li>
<li>파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</span></li>

</li>

<br>
<p><strong><span style="">제7조 (개인정보의 안전성 확보 조치)</span></strong></p>
<p><span style="">회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</span></p>

<li>개인정보 취급 직원의 최소화 및 교육<br />개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</span></li>
<li>해킹 등에 대비한 기술적 대책<br />회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신&middot;점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</span></li>
<li>개인정보의 암호화<br />이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</span></li>
<li>개인정보에 대한 접근 제한<br />개인정보를 처리하는데이터베이스 시스템에 대한 접근권한의부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</span></li>
<li>문서보안을 위한 잠금 장치 사용<br />개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다</span></li>
<li>비인가 자에 대한 출입 통제<br />개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대한 출입통제 절차를 수립, 운영하고 있습니다.</span></li>

<br>
<p><strong><span style="">제8조 (개인정보 자동 수집 장치의 설치&middot;운영 및 거부에 관한 사항)</span></strong></p>

<li>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 쿠키를 사용합니다.</span></li>
<li>쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.<br /><br />쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안 접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br />쿠키의 설치&middot;운영 및 거부 : 웹 브라우저 상단의 도구&gt;인터넷옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</span></li>

<br>
<p><strong><span style="">제9조 (개인정보 보호책임자)</span></strong></p>

<li>회사는 개인정보 처리에 관하 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br /><br />개인정보 보호책임자<br />성명 : 김명일<br />연락처 : 1566-7125 <br />이메일 : golf-yacht@golf-yacht.com</span></li>
<li>정보주체께서는 회사의 서비스 또는 사업을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</span></li>

<br>
<p><strong><span style="">제10조 (개인정보 열람청구)</span></strong></p>
<p><span style="">정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</span></p>

<br>
<p><strong><span style="">제11조 (권익침해 구제방법)</span></strong></p>

<li>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의신고, 상담에 대하여는 아래의기관에 문의하시기 바랍니다.<br /><br />개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br />개인정보침해신고센터 : (국번없이) 118 (priacy.kisa.or.kr)<br />대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br />검찰청 : (국번없이) 182 (cyberbureau.police.go.kr)</span></li>
<li>「개인정보 보호법」제35조, 제36조, 제37조의규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는바에 따라 행정심판을 청구할 수 있습니다.<br /><br />행정심판에 대한 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</span></li>

<br>
<p>이 개인정보처리방침은 2023년 09월 01일부터 적용됩니다</p>

`,
        },
        {
          title: '[선택]마케팅 수신 동의',
          desc: `주식회사 골프앤요트에 회원가입 신청하신분께 선택적으로 수집하는 개인정보는 [개인정보의 수립목적 및 이용목적]에서 고지한 범위내에서 사용하며, 동 범위를 초과하여 이용하거나, 타인 또는 타가입, 기관에 제공하지 않습니다. 정보통신망법 규정에 따라 선택적으로 수집하는 개인정보 항목, 개인정보 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내드립니다.<br><br><table style="border:1px solid #666; margin-bottom:1px; width: 100%; border-collapse: collapse;">
  <thead>
    <tr>
      <th style="border: 1px solid #666; padding: 5px;">목적</th>
      <th style="border: 1px solid #666; padding: 5px;">항목</th>
      <th style="border: 1px solid #666; padding: 5px;">보유기간</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="border: 1px solid #666; padding: 5px;">골프앤요트 판촉 행사 및 각종 이벤트 행사관련 정보 안내 제반 마케팅 활동, 당사 상품/서비스 안내 및 권유</td>
      <td style="border: 1px solid #666; padding: 5px;">이메일, 휴대폰번호</td>
      <td style="border: 1px solid #666; padding: 5px;">회원탈퇴 시 또는 법정 의무 보유 기간</td>
    </tr>
    <tr>
      <td style="border: 1px solid #666; padding: 5px;">상품권 충전 및 결제서비스 이용</td>
      <td style="border: 1px solid #666; padding: 5px;">생년월일, 성별</td>
      <td style="border: 1px solid #666; padding: 5px;">회원탈퇴 시 또는 법정 의무 보유 기간</td>
    </tr>
  </tbody>
</table>`,
        },
      ],

      // front ui
      pp: false,
      off: false,
      terms_idx: -1,
      timeout_id: null,
    };
  },
  methods: {
    // front ui
    pp_on(idx) {
      this.terms_idx = idx;
      this.pp = true;
    },
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style scoped></style>
